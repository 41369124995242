/* #504A27; #074795;  #349FDD  #27C87A*/

.login-form {
  /* border: 1px solid lightgrey; */
  border-radius: 10px;
  box-shadow: 1px 1px 10px #349fdd;
  background-color: #fff;
}

.text-color {
  color: #fec52e;
}

.custom-bg-btn {
  /* background-color: #1F1F1F; */
  background-color: #27c87a;
  /* #504A27; #074795;  #349FDD*/
  border-radius: 10px;
  height: 36px;
  margin-top: 4px;
  padding: 6px 27px !important;
}

/* .logo-image{
    height:30px;
    width:30px;
} */

.custom-text-color {
  color: #1f1f1f;
  font-weight: 600;
}

.custom-background-color {
  /* background-color: #074795; */
  /* #FEC52E; */
  background-color: #fff;
  box-shadow: 0px 2px 5px #27c87a;
}

.custom-cancel-btn {
  background-color: #eef5ff;
}

.sidemenu-item .active {
  background-color: #fec52e;
  color: white !important;
}

li a:hover {
  background-color: #074795;
  color: white !important;
}

.breadcum-text-color {
  color: #074795 !important;
  font-weight: bold;
}

.sidebar {
  height: calc(100vh - 85px);
  border-right: 1px solid #07479542;
}

.sidebar_settings {
  border-right: 1px solid #07479542;
}

.sidebar_settings li {
  list-style-type: none;
  border: 1px solid #07479542;
  cursor: pointer;
}

.sidebar li {
  list-style-type: none;
  border-bottom: 1px solid #07479542;
}

a.active li {
  background-color: #074795;
  color: #fff;
}

.sidebar li:hover,
.sidebar_settings li:hover {
  background-color: #074795;
  color: #fff;
}

.sidebar li.active {
  background-color: #fec52e;
  color: white;
}

.nav-list li a.active {
  background-color: #fec52e;
  color: white;
}

.nav-link.active {
  background-color: #fec52e;
  color: white;
}

.nav-item:hover {
  background-color: white !important;
  color: black !important;
}

.content-scroll {
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.table_new_ui th {
  border: none;
}

.table_new_ui th {
  /* background-color: #FEC52E !important;
    color: black !important; */
  background-color: #074795 !important;
  color: #fff !important;
}

.table-header {
  background-color: #fec52e !important;
  color: white !important;
}

.table_new_ui th,
.table_new_ui td {
  font-size: 12px;
  color: #333333;
  font-weight: 400;
}

.breadcrumb>li+li:before {
  content: "" !important;
  text-decoration: none !important;
}

.breadcrumb {
  padding: 10px 16px;
  font-size: 14px;
  color: #aaa !important;
  letter-spacing: 2px;
  border-radius: 5px !important;
}

.page-link {
  color: #fec52e !important;
}

.page-link.active {
  background-color: #fec52e !important;
  color: white !important;
}

.page-link:hover {
  background-color: #fec52e !important;
  color: white !important;
}

.active>.page-link,
.page-link.active {
  background-color: #fec52e !important;
  color: white !important;
}

.disabled {
  background-color: #eef5ff !important;
  color: black !important;
}

.search-position {
  position: relative;
  top: -33px;
  left: 45%;
  padding: 6.5px 5px;
  cursor: pointer;
}

.search-position i.bi {
  font-size: 18px;
}

/* input[type="text"], input[type="date"], input[type="email"], input[type="number"] {
    height: 31px;
} */

/* input[type="date"] {
    height: 31px;
}

input[type="email"] {
    height: 31px;
}

input[type="number"] {
    height: 31px;
} */

.pagination {
  text-decoration: none !important;
  list-style-type: none;
  --bs-pagination-border-color: #fec52e !important;
}

.page-item {}

.page-item.disabled {
  background-color: #eef5ff !important;
  color: black !important;
  --bs-pagination-disabled-bg: #eef5ff !important;
  --bs-pagination-disabled-border-color: #fec52e;
  --bs-pagination-disabled-color: black;
}

.page-item:hover {
  color: white !important;
}

.active>.page-link,
.page-link.active {
  border-color: #fec52e !important;
}

.page-link:focus {
  box-shadow: none !important;
}

.nav-tabs .nav-item .nav-link {
  color: #fff;
  border: 1px solid #074795 !important;
  padding: 8px;
  font-size: 14px;
}

.nav-tabs .nav-item .nav-link:hover {
  background-color: #074795 !important;
  color: #fff !important;
}

.nav-tabs .nav-item .nav-link.active {
  background-color: #074795 !important;
  color: #fff !important;
}

.nav-tabs {
  border-bottom: var(--bs-nav-tabs-border-width) solid #074795 !important;
}

.modal-box-shadow {
  box-shadow: 1px 1px 10px black;
}

.send-icon {
  height: 13px;
  width: 13px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.editor-position {
  position: relative;
  bottom: 60px !important;
}

.users-editors-table {
  position: relative;
  top: -40px !important;
}

@media (max-width: 768px) {
  .editor-position {
    bottom: 0px !important;
  }

  .users-editors-table {
    position: relative;
    top: 0px !important;
  }
}

.btn.custom-bg-btn-outline-light,
.btn.custom-bg-btn-outline-light:hover {
  border: 1px solid #074795;
}

.custom_link {
  cursor: pointer;
  color: blue;
  font-weight: 700;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-2gftvx.Mui-selected {
  background-color: #074795 !important;
  color: #fff !important;
}

.modal-show {
  display: block !important;
}

.modal-hide {
  display: none !important;
}

.modal-bg {
  border-radius: 10px !important;
  border: 0px !important;
  box-shadow: 1px 1px 10px black;
}

/* .modal-header {
    border-bottom: none !important;
}

.modal-footer {
    border-top: none !important;
} */

.back-icon {
  margin-bottom: 3px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
}

.modal-backdrop.show {
  opacity: 1 !important;
}

/* ======================= slider start   ============================= */

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 12px;
  border-radius: 5px;
  background: #074795;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: black;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: black;
  cursor: pointer;
}

/* ======================= slider end   ============================= */

.post_img {
  height: 200px;
  width: 500px;
  position: relative;
}

.post_img img,
.post_img video {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  /* object-fit: contain; */
}

.post_video_play {
  font-size: 26px;
  position: absolute;
  top: 110px;
  right: 130px;
  color: #fec52e;
}

.feed_text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}

.hide_setting {
  display: none;
  position: absolute;
  right: 20px;
  top: 48px;
  background: #fff;
  z-index: 10;
}

.show_setting:hover+.hide_setting,
.hide_setting:hover {
  display: block;
  position: absolute;
  right: 20px;
  top: 48px;
  background: #fff;
  z-index: 21;
}

.carousel-item,
.vide_player {
  height: 300px;
  background: #000;
}

.carousel-item .carousel_image,
.vide_player video {
  height: 100%;
  object-fit: contain;
}

.login-spinner-border {
  --bs-spinner-width: 1.5rem !important;
  --bs-spinner-height: 1.5rem !important;
}

.slider-wrapper.axis-horizontal,
.slide.selected div,
.slide.selected div img {
  height: calc(100vh - 150px);
}

.search-wrapper.searchWrapper {
  height: 38px;
}

.mt_12 {
  margin-top: 12px;
}

.source_data {
  min-height: 230px;
  max-height: calc(100vh - 320px);
  overflow-y: auto;
}

.details_view .carousel-root .carousel.carousel-slider .slider-wrapper.axis-horizontal,
.details_view .carousel-root .carousel.carousel-slider .slider-wrapper.axis-horizontal .slider.animated .slide.selected.previous div {
  height: 250px;
  /* calc(100vh - 300px) !important; */
}

.details_view .carousel-root .carousel.carousel-slider .slider-wrapper.axis-horizontal .slider.animated .slide.selected.previous div img {
  height: 100%;
  object-fit: fill;
}

.login_logo {
  height: 40px;
  top: -60px;
  position: relative;
}

.login_logo img {
  height: 150px;
}

.selected_submit {
  text-align: end;
  position: fixed;
  right: 100px;
  top: 60px;
  z-index: 20;
}

.custom-bg-btn-disabled {
  background-color: #27c87a75 !important;
}

.daily_talking_point {
  padding: 2px 8px;
  margin: 0px 5px;
  color: white;
}

.daily_talking_point_green {
  background: green;
}

.daily_talking_point_yellow {
  background: #d0d01a;
}

.daily_talking_point_red {
  background: red;
}

.custom_spinner {
  position: absolute;
  top: 60px;
  left: 255px;
  height: 100vh;
  width: calc(100vw - 272px) !important;
  background: #000000bf;
  z-index: 25;
}

.custom_spinner .spinner-border {
  position: absolute;
  top: 50%;
  color: white;
}

@media (max-width: 768px) {
  .custom_spinner {
    position: absolute;
    top: 60px;
    left: 0px;
    height: 100vh;
    width: calc(100vw - 0px) !important;
    background: #000000bf;
    z-index: 25;
  }
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 0.375rem;
  color: #fff;
  background-color: #074795;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  height: 36px;
  padding: 6px 27px !important;
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  height: 36.6px !important;
  padding: 6px 12px !important;
  margin-top: 2px !important;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 26.6px !important;
  padding: 6px 12px !important;
}

.MuiStack-root.css-b3dvpx-MuiStack-root {
  overflow: hidden !important;
}

.table_text_ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  width: 400px;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred,
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  max-height: 150px;
  overflow: auto;
}

.css-13bvbr1-MuiStack-root> :not(style)+ :not(style) {
  margin-top: 2px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #dee2e6 !important;
}

.user_status_feedback {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-top: 5px;
}

.reject_status {
  background: red;
}

.success_status {
  background: #27c87a;
}

.pending_status {
  background: orange;
}

.dashboard_box {
  box-shadow: 0px 0px 10px #27c87a;
  border-radius: 8px;
  width: 32%;
}

.dashboard_box_child {
  width: 48%;
}

.MuiDateRangeCalendar-root>div:first-child {
  display: none;
}

.MuiTextField-root,
.MuiPickersTextField-root {
  min-width: 250px !important;
}

.MuiStack-root {
  width: 250px;
  overflow: hidden;
  padding: 0px;
}


/* .triangle {
  position: relative;
  height: 300px;
  clip-path: polygon(80% 100%, 20% 100%, 50% 0);
}

.triangle div {
  background: #074795;
  width: 100%;
  height: 100px;
  margin: 0 auto 10px;
  color: black;
  line-height: 100px;
  text-align: center;
  font-size: 0.8em;
} */

.triangle {
  position: relative;
  /* height: 400px;
  clip-path: polygon(80% 0, 20% 0, 50% 100%, 50% 100%); */
  height: 500px;
  clip-path: polygon(80% 0, 20% 0, 57% 100%, 43% 100%);

  /* clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%); */
}

.triangle div {
  background: #4473C5;
  /* width: 100%;  */
  height: 100px;
  margin: 0 auto 2px;
  color: black;
  /* line-height: 100px; */
  text-align: center;
  font-size: 16px;
  font-weight: 700;

}

.custom_blue_btn {
  background: #4473C5;
  color: whitesmoke;
}

.platform_text {
  width: 30%;
  padding: 2% 8%;
}

.post_text {
  width: 30%;
  padding: 0% 6%;
}